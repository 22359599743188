import { getData, postData } from '../../utill/api'
import config from '../../utill/config'
import { showMsg } from '../../utill/lib';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_EMAILTEMPLATE_FETCH = 'GET_EMAILTEMPLATE_FETCH';
export const GET_EMAILTEMPLATE_FAILURE = 'GET_EMAILTEMPLATE_FAILURE';
export const GET_EMAILTEMPLATE_SUCCESS = 'GET_EMAILTEMPLATE_SUCCESS';
export const GET_EMAILTEMPLATE_MESSAGE = 'GET_EMAILTEMPLATE_MESSAGE';


export const createEmailTemplate = (data) => {
  let addEmailApi = config.api.basepoint + config.api.createEmail;
  try{
    postData({url:addEmailApi, data, auth:'token' })
    .then(data => {
        if(data.errors){
            //Will fire GA here
            showMsg({msg:'There are some technical issues. Please try after sometime.', event:'error'});
        }
        //If there is some error, print message
        else if(data.message){
            showMsg({msg:data.message, event:'message'})
        }
        //On Success
        else{
            showMsg({msg:'Template Added Sucessfuly', event:'success'});
            // To blank field after submition
            document.querySelector('[name="id"]').value = '';
            document.querySelector('[name="subject"]').value = '';
            document.querySelector('[name="richTextField"]').contentWindow.document.body.innerHTML = '';
        }
    })
    .catch(error => {
        console.log(error)
        showMsg({msg:'There are some technical issues. Please try after sometime.', event:'error'});
    });
  }
  catch(err){
    console.log(err)
  }
}

export const fetch_template = (props, id) => {
  let getEmail = config.api.basepoint + config.api.getEmail;
  props._fetchEmail()
  try{
    getData({url: getEmail, auth:'token', customheader:{'emailTemplateId': id}})
    .then(data => {
      //If there is some error, print message
      if(data.message){
        props._emailMessage(data.message)
      }
      //On Success
      else{
        props._loadEmail(data)
      }
    }).catch(error => {
      console.log(error)
      props._emailFailure()
    });
  }
  catch(err){
    console.log(err)
    props._emailFailure()
  }
}