import { getData, postData } from '../../utill/api'
import config from '../../utill/config'
import { showMsg } from '../../utill/lib';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_DISCOUNT_FAILURE = 'GET_DISCOUNT_FAILURE';
export const GET_DISCOUNT_SUCCESS = 'GET_DISCOUNT_SUCCESS';
export const GET_DISCOUNT_MESSAGE = 'GET_DISCOUNT_MESSAGE';
export const GET_DISCOUNT_FETCH = 'GET_DISCOUNT_FETCH';
export const DONE  = 'DONE';

export const loadCoupons = (payload) => {
  return {
    type: GET_DISCOUNT_SUCCESS,
    payload: payload
  }
}

export const fetchCoupons = () => {
  return {
    type: GET_DISCOUNT_FETCH
  }
}

export const done = () => {
  return {
    type: DONE
  }
}

export const getFailure = (payload) => {
  return {
    type : GET_DISCOUNT_FAILURE,
    payload:payload
  }
}

export const message = (payload) => {
  return {
    type : GET_DISCOUNT_MESSAGE,
    payload: payload
  }
}

export const fetch_DISCOUNT_List = (props) => {
    
    let GetDiscountApi = config.api.basepoint + config.api.discountList;
    
    props._couponFetch();

    postData({url:GetDiscountApi, auth:'token'})
      .then(data => {
        props._loadAllCoupons(data);
    })
    .catch(error => {
      props._couponfailure(error)
      showMsg({msg: config.msg.error, event:'error'})
      console.log(error)
    });
}