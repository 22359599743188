import * as actionTypes from '../../action/orders/Orders'

const initialState = {
    error: false,
    isOrderFetching: false,
    errormessage: ''
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_ORDERS_FETCH:
            return {
                ...state,
                isFetching: true,
                isOrderFetching: true,
            };
        case actionTypes.GET_ORDERS_SUCCESS :
            return {
                ...state,
                isFetching: false,
                isOrderFetching: false,
                error: false,
                orders: action.payload
            };
        
        case actionTypes.GET_ORDERS_FAILURE :
            return {
                ...state,
                isFetching: false,
                isOrderFetching: false,
                error: action.payload
            };
        
        case actionTypes.GET_ORDERS_MESSAGE:
            return {
                ...state,
                isFetching: false,
                isOrderFetching: false,
                error: false,
                errormessage: action.payload
            };
        case actionTypes.DONE:
            return {
                ...state,
                isFetching: false,
                isOrderFetching: false,
                orders: state.orders
            }
        
        default:
            return state;
    }
}

export default reducer;