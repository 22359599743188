import React, { Component } from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';



import './App.scss';
import './assets/css/common.scss'
import {setDomains } from './utill/lib';
import asynComponet from './hoc/asyncComponent'
import { form_validate } from './utill/validation';

const Login = asynComponet( () => { return import(/*webpackChunkName: "Login" */'./containers/login/Login');})
const DashBoard = asynComponet( () => {return import(/*webpackChunkName: "DashBoard" */'./containers/dashboard/Dashboard');})
const ClientDashBoard = asynComponet( () => {return import(/*webpackChunkName: "ClientDashBoard" */'./containers/clientpanel/dashboard/Dashboard');})
const ViewClient = asynComponet( () => {return import(/*webpackChunkName: "ViewClient" */'./containers/viewclient/ViewClient');})
const Clients = asynComponet( () => {return import(/*webpackChunkName: "Clients" */'./containers/clients/Clients');})
const AddClient = asynComponet( () => {return import(/*webpackChunkName: "AddClient" */'./containers/addclients/AddClients');})
const page404 = asynComponet( () => {return import(/*webpackChunkName: "page404" */'./containers/page404/Page404');})
const Forgot = asynComponet( () => {return import(/*webpackChunkName: "Forgot" */'./containers/forgot/Forgot');})
const Message = asynComponet( () => { return import(/*webpackChunkName: "Message" */'./containers/messages/Message');})
const EditClient = asynComponet( () => { return import(/*webpackChunkName: "EditClient" */'./containers/editclient/EditClient');})
const Settings = asynComponet( () => { return import(/*webpackChunkName: "Settings" */'./containers/settings/Settings');})
const Services = asynComponet( () => { return import(/*webpackChunkName: "Services" */'./containers/services/Services');})
const ClientServices = asynComponet( () => { return import(/*webpackChunkName: "ClientServices" */'./containers/services/ClientServices');})
const AddServices = asynComponet( () => { return import(/*webpackChunkName: "AddServices" */'./containers/addservice/AddService');})
const EditServices = asynComponet( () => { return import(/*webpackChunkName: "EditServices" */'./containers/editservices/EditServices');})
const Orders = asynComponet( () => { return import(/*webpackChunkName: "Orders" */'./containers/orders/Orders');})
const ClientOrders = asynComponet( () => { return import(/*webpackChunkName: "ClientOrders" */'./containers/clientpanel/orders/Orders');})
const Projects = asynComponet( () => { return import(/*webpackChunkName: "Projects" */'./containers/project/Projects');})
const Invoice = asynComponet( () => { return import(/*webpackChunkName: "Invoice" */'./containers/invoices/Invoices');})
const AddInvoice = asynComponet( () => { return import(/*webpackChunkName: "Invoice" */'./containers/invoices/AddInvoices');})
const ClientInvoice = asynComponet( () => { return import(/*webpackChunkName: "ClientInvoice" */'./containers/clientpanel/invoices/Invoices');})
const Smtp = asynComponet( () => { return import(/*webpackChunkName: "Smtp" */'./containers/smtp/Smtp');})
const AddEmail = asynComponet( () => { return import(/*webpackChunkName: "AddEmail" */'./containers/addemail/AddEmail');})
const EmailTemplates = asynComponet( () => { return import(/*webpackChunkName: "EmailTemplates" */'./containers/emailtemplates/EmailTemplates');})
const Company = asynComponet( () => { return import(/*webpackChunkName: "Company" */'./containers/company/Company');})
const Branding = asynComponet( () => { return import(/*webpackChunkName: "Branding" */'./containers/branding/Branding');})
const Payment = asynComponet( () => { return import(/*webpackChunkName: "Payment" */'./containers/payment/Payment');})
const PgSetting = asynComponet( () => {  return import(/*webpackChunkName: "PgSetting" */'./containers/pgsetting/PgSetting');})
const Checkout = asynComponet( () => { return import(/*webpackChunkName: "Checkout" */'./containers/checkout/Checkout');})
const Success = asynComponet( () => { return import(/*webpackChunkName: "Success" */'./containers/checkout/Success');})
const Failure = asynComponet( () => { return import(/*webpackChunkName: "Failure" */'./containers/checkout/Failure');})
const Discount = asynComponet( () => { return import(/*webpackChunkName: "Discount" */'./containers/discount/Discount') } )
const AddDiscount = asynComponet( () => { return import(/*webpackChunkName: "AddDiscount" */'./containers/adddiscount/AddDiscount') } )
// Subscription
const Subscription = asynComponet( () => { return import(/*webpackChunkName: "Subscription" */'./containers/subscription/Subscription') } )
const ViewSubscription = asynComponet( () => { return import(/*webpackChunkName: "Subscription" */'./containers/subscription/Detail') } )
/** Layouts **/  
const LayoutContainer = asynComponet( () => { return import(/*webpackChunkName: "LayoutContainer" */'./layouts/LayoutContainer');})
const StructuredLayout = asynComponet( () => { return import(/*webpackChunkName: "StructuredLayout" */'./layouts/Structured');})
const BaseLayout = asynComponet( () => { return import(/*webpackChunkName: "BaseLayout" */'./layouts/Base');})

// for client
const ClientStructuredLayout = asynComponet( () => { return import(/*webpackChunkName: "ClientStructuredLayout" */'./layouts/ClientStructured');})

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      islogged : false
    };
  }

  componentDidMount(){}

  componentWillMount() {
    let domains = setDomains()
    this.unlisten = browserHistory.listen((location) => {
      // Fire GA Here
      if(window && window.ga && domains.subDomain != 'servlyabhi'){
        window.ga('send', {
          hitType: 'pageview',
          page: location.pathname,
          title: document.title
        });
      }
    });

    // for form validation
    window.addEventListener('change', function(e){
      if(e.target.getAttribute('validate')){
        form_validate(e);
      }
    })
  }
  
  componentWillUnmount() {
      this.unlisten();
  }



  render(){
    return (
      <div>
        <Router history = {browserHistory}>
          
            <Route path="/" component={LayoutContainer} exact>
                <Route component={StructuredLayout}>
                  <Route path='/dashboard' component={DashBoard}/>
                  <Route path='client' component={Clients}/>
                  <Route path='client/addclient' component={AddClient}/>
                  <Route path='client/clientinfo/:id' component={ViewClient}/>
                  <Route path='client/edit/:id' component={EditClient}/>
                  <Route path='message' component={Message}/>
                  <Route path='settings' component={Settings}/>
                  <Route path="settings/smtp" component={ Smtp } />
                  <Route path="settings/email" component={ EmailTemplates } />
                  <Route path="settings/email/createemail" component={ AddEmail } />
                  <Route path="settings/email/createemail/edit/:id" component={ AddEmail } />
                  <Route path="settings/company" component={Company} />
                  <Route path="settings/branding" component={Branding} />
                  <Route path="settings/payment" component={Payment} />
                  <Route path="settings/payment/:type" component={PgSetting} />
                  <Route path="service" component={Services} />
                  <Route path="service/addservice" component={AddServices} />
                  <Route path="service/edit/:id" component={AddServices} />
                  <Route path="orders" component={Orders} />
                  <Route path="orders/order/:id" component={Projects} />
                  <Route path="invoices" component={ Invoice } />
                  <Route path="invoices/new" component={ AddInvoice } />
                  <Route path="/invoices/edit/:id" component={ AddInvoice } />
                  <Route path="/discount" component={ Discount } />
                  <Route path="/discount/addnew" component={ AddDiscount } />
                  <Route path="/discount/edit/:id" component={ AddDiscount } />
                  <Route path="/discount/view/:vid" component={ AddDiscount } />
                  <Route path="/subscription" component={ Subscription } />
                  <Route path="/subscription/detail" component={ ViewSubscription } />
                </Route>

                <Route component={ClientStructuredLayout}>
                  <Route path='/client/dashboard' component={ClientDashBoard}/>
                  <Route path='/client/services' component={ClientServices}/>
                  <Route path='/client/invoices' component={ClientInvoice}/>
                  <Route path='/client/orders' component={ClientOrders}/>
                </Route>
  
                <Route component={BaseLayout}>
                  <IndexRoute component={Login} exact/>
                  <Route path='login' component={Login} exact/>
                  <Route path='forgot' component={Forgot} exact/>
                  {/* <Route path='register' component={CreateCompany}/> */}
                  <Route path="services" component={ClientServices} />
                  <Route path="checkout/:sid/:vid" component={Checkout} />
                  <Route path="checkout/:sid" component={Checkout} />
                  <Route path="payment/success" component={Success} />
                  <Route path="payment/failure" component={Failure} />
                </Route>
            
                <Route path="*" component={page404} />
            </Route>
        </Router>
      </div>
      
    );
  }
}

export default App;
