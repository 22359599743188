import * as actionTypes from './../../action/addclient/AddClient'

const initialState = {
    error: false,
    isFetching: false,
    errormessage: '',
    redirectToReferrer: false
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.ADD_CLIENT_SUCCESS :
            return {
                ...state,
                isFetching: false,
                error: false,
                redirectToReferrer: true
            };
        
        case actionTypes.ADD_CLIENT_MESSAGE :
            return {
                ...state,
                isFetching: false,
                error: action.payload,
                redirectToReferrer: false
            };
        
        case actionTypes.ADD_CLIENT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload,
                redirectToReferrer: true
            };
        
        default:
            return state;
    }
}

export default reducer;