import { getData } from '../../utill/api'
import config from '../../utill/config'

export const GET_SERVICE_FAILURE = 'GET_SERVICE_FAILURE';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_MESSAGE = 'GET_SERVICE_MESSAGE';
export const GET_SERVICE_FECTH = 'GET_SERVICE_FECTH';

//Fetch Country List for dropdown
export const fetchServiceInfo = (props, id, domain) => {
    
    //dispatch action to set isfetching === true
    props._fectchingInfo();

    let serviceInfoApi = config.api.basepoint + config.api.getService;

    getData({url:serviceInfoApi, auth:'token', customheader:{serviceId: id, subdomain: domain}})
    .then((data) => {
        props._loadServiceInfo(data)
    })
    .catch( error => {
        props._serviceInfoFailure(error)
    });
}