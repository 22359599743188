import { getData, postData } from '../../utill/api'
import config from '../../utill/config'
import { logout, _localStorage } from '../../utill/lib';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_DASHBOARD_FETCH = 'GET_DASHBOARD_FETCH';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_MESSAGE = 'GET_DASHBOARD_MESSAGE';


export const fetchDashBoard = (props) => {
  
    //Trigger action fetch
    props._fetching();
    
    let rl = _localStorage().get(config.site.loc);
    if(rl == undefined) return false 
    rl = JSON.parse(rl);

    let getDashApi = config.api.basepoint + (rl.key == 2 ? config.api.dashboard : rl.key == 3 ? config.api.clientDashboard : '')
    
    try{
      getData({url:getDashApi, auth:'token'})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          props._message(data.message);
          props._failure(data.message)
        }
        // If User is not authorize
        else if(data.status && (data.status).toLowerCase() == 'forbidden'){
          logout();
        }
        //On Success
        else{
          props._success(data);
        }
      }).catch(error => {
        props._failure(error.message)
      });
  }catch(err){
      props._failure(err)
      console.log(err)
  }
}