import { getData } from '../../utill/api'
import config from '../../utill/config'

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_PGDETAILS_FETCH = 'GET_PGDETAILS_FETCH';
export const GET_PGDETAILS_FAILURE = 'GET_PGDETAILS_FAILURE';
export const GET_PGDETAILS_SUCCESS = 'GET_PGDETAILS_SUCCESS';
export const GET_PGDETAILS_MESSAGE = 'GET_PGDETAILS_MESSAGE';


export const fetch_PGDETAILS_Details = (props, thiss) => {
  
    let getPgDetails = config.api.basepoint + config.api.getpaymentDetails;
    
    props._fetchPgDetails();

    try{
      getData({url:getPgDetails, auth:'token'})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          props._PgDetailsMessage(data.message)
        }
        //On Success
        else{
          thiss.setState({detailForm: data})
          props._loadPgDetails(data)
        }
      }).catch(error => {
        console.log(error)
        props._PgDetailsFailure(error)
      });
  }catch(err){
      console.log(err)
  }
}