import * as actionTypes from './../../action/viewclient/ViewClient'

const initialState = {
    client: {},
    billingDetail:{},
    error: false,
    isFetching: false,
    errormessage: ''
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_CLIENT_FECTH :
            return {
                ...state,
                isFetching: true,
                error: false,
            };
        case actionTypes.GET_CLIENT_SUCCESS :
            return {
                ...state,
                viewclient: action.payload,
                isFetching: false,
                error: false,
            };
        
        case actionTypes.GET_CLIENT_FAILURE :
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        
        case actionTypes.GET_CLIENT_MESSAGE:
            return {
                ...state,
                isFetching: false,
                error: false,
                errormessage: action.payload
            };
        
        default:
            return state;
    }
}

export default reducer;