import config from '../../utill/config'
import { showMsg, isClient } from '../../utill/lib'
import { getData } from '../../utill/api';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_SUBSCRIPTION_LIST_FETCH = 'GET_SUBSCRIPTION_LIST_FETCH';
export const GET_SUBSCRIPTION_LIST_FAILURE = 'GET_SUBSCRIPTION_LIST_FAILURE';
export const GET_SUBSCRIPTION_LIST_SUCCESS = 'GET_SUBSCRIPTION_LIST_SUCCESS';
export const GET_SUBSCRIPTION_LIST_MESSAGE = 'GET_SUBSCRIPTION_LIST_MESSAGE';
export const GET_SUBSCRIPTIONDETAIL_FETCH = 'GET_SUBSCRIPTIONDETAIL_FETCH';

// For Details
export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';


export const fetch_Subscription_List = ({props, customheader}) => {
    props._fetchSubscriptionList();

    let getAllServicesApi = config.api.basepoint + config.api.getSubscription;
    
    getData({url: getAllServicesApi, auth:'token', customheader})
    .then(data => {
      //If there is some error, print message
      if(data.message){
        props._subscriptionListMessage(data.message);
        showMsg({msg:data.message, event:'message'})
      }
      else if(data.error){
        props._subscriptionListFailure(data.error);
        showMsg({msg:config.msg.error, event:'message'})
      }
      //On Success
      else{
        props._loadSubscriptionList(data)
      }
    })
    .catch(error => {
      console.log(error)
      props._subscriptionListFailure(error);
    });
}

export const fetch_Subscription_Detail = (props) => {
  props._fetchSubscriptionDetail();

  let getServicesDetailApi = config.api.basepoint + config.api.getSubscriptionDetail;
  let customheader = { 'subscriptionId' : 2 }
  getData({url: getServicesDetailApi, auth:'token', customheader})
  .then(data => {
    //If there is some error, print message
    if(data.message){
      props._subscriptionListMessage(data.message);
      showMsg({msg:data.message, event:'message'})
    }
    else if(data.error){
      props._subscriptionListFailure(data.error);
      showMsg({msg:config.msg.error, event:'message'})
    }
    //On Success
    else{
      props._loadSubscriptionList(data)
    }
  })
  .catch(error => {
    console.log(error)
    props._subscriptionListFailure(error);
  });
}
