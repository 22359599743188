import { getData, postData } from '../../utill/api'
import config from '../../utill/config'
import { showMsg, gevent, logfile } from '../../utill/lib';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_GETCOMPANY_FETCH = 'GET_GETCOMPANY_FETCH';
export const GET_GETCOMPANY_FAILURE = 'GET_GETCOMPANY_FAILURE';
export const GET_GETCOMPANY_SUCCESS = 'GET_GETCOMPANY_SUCCESS';
export const GET_GETCOMPANY_MESSAGE = 'GET_GETCOMPANY_MESSAGE';


export const fetch_company = (props) => {
  
    let getCompanyApi = config.api.basepoint + config.api.getCompany;
    
    props._fetchCompany();

    try{
      getData({url:getCompanyApi, auth:'token'})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          props._companyMessage(data.message)
          //Error log
          logfile({event:1, method: 'saveCompany', path: 'con/company', error: data.message})
        }
        //On Success
        else{
          props._loadCompany(data)
        }
      }).catch(error => {
        console.log(error)
        props._companyFailure(error)
        //Error log
        logfile({event:0, method: 'saveCompany', path: 'con/company', error: error.message})
      });
  }catch(err){
      console.log(err)
      //Error log
      logfile({event:0, method: 'saveCompany', path: 'con/company', error: err.message})
  }
}

export const saveCompany = (props, data) => {
  let saveCompanyApi = config.api.basepoint + config.api.updateComapny;

  try{
    postData({url:saveCompanyApi, data:data, auth:'token'})
    .then(data => {
      if(data.errors){
          //Will fire GA here
          showMsg({msg:'There are some technical issues. Please try after sometime.', event:'error'});
          //Error log
          logfile({event:1, method: 'saveCompany', path: 'con/company', error: data.errors})
      }
      //If there is some error, print message
      else if(data.message){
          showMsg({msg:data.message, event:'message'})
          //Error log
          logfile({event:0, method: 'saveCompany', path: 'con/company', error: data.message})
      }
      //On Success
      else{
          showMsg({msg:'Details Saved Sucessfuly', event:'success'});
      }
    })
    .catch(error => {
        console.log(error)
        showMsg({msg:'There are some technical issues. Please try after sometime.', event:'error'});
        //Error log
        logfile({event:0, method: 'saveCompany', path: 'con/company', error: error.message})
    });
  }
  catch(err){
    console.log(err)
    //Error log
    logfile({event:0, method: 'saveCompany', path: 'con/company', error: err.message})
  }
}