import * as actionTypes from '../../action/checkout/Checkout'

const initialState = {
    error: false,
    isFetching: false,
    errormessage: ''
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_SERVICEDETAILS_FETCH:
            return {
                ...state,
                isFetching: true
            };
        case actionTypes.GET_SERVICEDETAILS_SUCCESS :
            return {
                ...state,
                isFetching: false,
                error: false,
                servicedetail: action.payload
            };
        
        case actionTypes.GET_SERVICEDETAILS_FAILURE :
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        
        case actionTypes.GET_SERVICEDETAILS_MESSAGE:
            return {
                ...state,
                isFetching: false,
                error: false,
                errormessage: action.payload
            };
        
        default:
            return state;
    }
}

export default reducer;