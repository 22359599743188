import { showMsg, getNextSibling, findParent } from "./lib";

export const form_validate = (event) => {
    event.preventDefault();
    let form = null;
    let error = [];
    let inputs = [];
    let textRegex = /^[a-zA-Z ]+$/;
    let numRegex = /^\d+$/;
    let emailRegex = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,30}){1,3})$/igm;
    let domainRegex = /^([a-zA-Z0-9][-a-zA-Z0-9]*[a-zA-Z0-9]\.)+([a-zA-Z0-9]{3,5})$/g;

    if(event.target.tagName.toLowerCase() == 'form'){
        inputs = event.target.querySelectorAll('[validate="true"]')
        form = event.target;
    }else{
        inputs = [event.target];
        form = event.target.form;
    }

    inputs.forEach((ele) => {
        
        let nextSib = getNextSibling(ele, '[form_err_msg]');
        // Check is field empty
        if(ele.getAttribute('req') && ele.value == "")
        {
            //addError({action:'add', nextEle: nextEle, msg: 'Please fill in this field', ele: ele });
            let txtName = ele.getAttribute('name');
            error.push({ [txtName] : 'required' })
        }

        // validate domain
        else if(ele.getAttribute("data-type") == "domain" && ele.getAttribute('validate')){
            var txtName = ele.getAttribute("name");
            if(!domainRegex.test(ele.value))
            {
                error.push({ [txtName] : 'domain' });
            }else{
                addError({action:'remove', nextEle: nextSib, ele: ele });
                if (error[txtName]){
                    error.pop([txtName]);
                }
            }
        }

        // Validation for select box
        else if(ele.getAttribute("data-type") == "select" && ele.getAttribute('validate')){
            var txtName = ele.getAttribute("name");
            if(ele.selectedIndex == 0)
            {   
                error.push({ [txtName] : 'select' });
            }else{
                addError({action:'remove', nextEle: nextSib, ele: ele });
                if (error[txtName]){
                    error.pop([txtName]);
                }
            }
        }
        
        // validation for data list
        else if(ele.getAttribute("data-type") && ele.getAttribute("data-type").indexOf('#') > -1 && ele.getAttribute('validate') && ele.value != ""){
            var obj = document.querySelector(ele.getAttribute("data-type"))
            obj = obj.querySelector("option[data-value='" + ele.value.toLowerCase() + "']");
            if(obj != null)
            {
                addError({action:'remove', nextEle: nextSib, ele: ele });
                var txtName = ele.getAttribute('name');
                if (error[txtName]){
                    error.pop([txtName]);
                }
            }else{
                var txtName = ele.getAttribute("name");
                error.push({ [txtName] : 'datalist' });
            }
        }

        // Check is value text or not
        else if(ele.getAttribute("data-type") == "text" && ele.getAttribute('validate') && ele.value != ""){
            if(!textRegex.test(ele.value))
            {   
                //addError({action:'add', nextEle: nextEle, msg: 'Only text are allowed A-Z', ele: ele });
                var txtName = ele.getAttribute("name");
                error.push({ [txtName] : 'string' });
            }else{
                addError({action:'remove', nextEle: nextSib, ele: ele });
                var txtName = ele.getAttribute('name');
                if (error[txtName]){
                    error.pop([txtName]);
                }
            }
        }
        // Check is value number or not
        else if(ele.getAttribute("data-type") == "number" && ele.getAttribute('validate') && ele.value != ""){
            if(!numRegex.test(ele.value))
            {   
                //addError({action:'add', nextEle: nextEle, msg: 'Only numbers are allowed 0-1', ele: ele });
                var txtName = ele.getAttribute("name");
                error.push({ [txtName] : 'number' });
            }else{
                addError({action:'remove', nextEle: nextSib, ele: ele });
                var txtName = ele.getAttribute('name');
                if (error[txtName]){
                    error.pop([txtName]);
                }
            }
        }
        // Check is value email or not
        else if(ele.getAttribute("data-type") == "email" && ele.getAttribute('validate') && ele.value != ""){
            
            if(!emailRegex.test(ele.value))
            {
                //addError({action:'add', nextEle: nextEle, msg: 'Please add valid email address', ele: ele });
                var txtName = ele.getAttribute("name");
                error.push({ [txtName] : 'email' });
            }else{
                addError({action:'remove', nextEle: nextSib, ele: ele });
                var txtName = ele.getAttribute('name');
                if (error[txtName]){
                    error.pop([txtName]);
                }
            }
        }
        else if(ele.value != undefined && ele.value != ''){
            addError({action:'remove', nextEle: nextSib, ele: ele });
            var txtName = ele.getAttribute('name');
            if (error[txtName]){
                error.pop([txtName]);
            }
        }

        //return error;
    })

    if(error.length > 0){
        error.forEach((el) => {
            let field = Object.keys(el)[0];
            let ele = form.querySelector('[name=' + field + ']');
            let nextEle = getNextSibling(ele, '[form_err_msg]');
            let msg = '';

            switch (el[field]){
                case 'string':
                    msg = 'Only letters are allowed A-Z'
                    break;
                case 'number':
                    msg = 'Only numbers are allowed 0-1'
                    break;
                case 'email':
                    msg = 'Please enter valid email address'
                    break;
                case 'required':
                    msg = 'Please fill in this field'
                    break;
                case 'datalist':
                    msg = 'Please select an option from list'
                    break;
                case 'select':
                    msg = 'Please select an option from dropdown'
                    break
                case 'domain':
                    msg = 'Please enter valid domain'
                    break
            }

            addError({action: 'add', nextEle: nextEle, msg: msg, ele})

        })
        return false;
    }else{
        return true;
    }

    // if(callback){
    //     callback();
    // }
    
}

const addError = ({action, nextEle, msg, ele}) => {
    if(action == 'remove'){
        if(nextEle != undefined){ nextEle.remove(); }   
        ele.classList.remove('fieldError');
    }
    else{
        // create html element for error msg
        let span = document.createElement("span");
        span.classList.add('form_error');
        span.setAttribute('form_err_msg', 'error');
        span.innerText = msg;
        
        // check if error element already exist first remove it
        if(nextEle != undefined){ nextEle.remove(); }   
        
        // append after form field
        // ele.after(span);
        ele.parentElement.append(span)

        ele.classList.add('fieldError');
    }
}