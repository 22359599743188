import { getData } from './../../utill/api'
import config from './../../utill/config'
import { showMsg } from '../../utill/lib';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_MESSAGE = 'GET_CLIENT_MESSAGE';
export const GET_CLIENT_FETCH = 'GET_CLIENT_FETCH';
export const DONE  = 'DONE';

export const fetch_Client_List = (props) => {
    
    let GetCientApi = config.api.basepoint + config.api.getallClient;
    
    props._clientFetch();
    getData({url:GetCientApi, auth:'token'})
      .then(data => {
        if (data.message) {
            //If there is some error, print message
            showMsg({ msg: data.message, event: 'error' });
            props._clientmessage(data.error.errorMessage)
        }else{
          //On Success
          props._loadAllClient(data)
        }
    })
    .catch(error => {
      console.log(error)
      props._clientfailure(error)
    });
}