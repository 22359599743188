import * as actionTypes from '../../action/discount/Discount'

const initialState = {
    error: false,
    errormessage: '',
    discount:{}
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_DISCOUNT_FETCH:
            return {
                ...state,
                isDiscount_fectchin: true
            };
        case actionTypes.GET_DISCOUNT_SUCCESS :
            return {
                ...state,
                isDiscount_fectchin: false,
                error: false,
                discount: action.payload
            };
        
        case actionTypes.GET_DISCOUNT_FAILURE :
            return {
                ...state,
                isDiscount_fectchin: false,
                error: action.payload
            };
        
        case actionTypes.GET_DISCOUNT_MESSAGE:
            return {
                ...state,
                isDiscount_fectchin: false,
                error: false,
                errormessage: action.payload
            };
        case actionTypes.DONE:
            return {
                ...state,
                isDiscount_fectchin: false,
                discount: state.discount
            }
        
        default:
            return state;
    }
}

export default reducer;