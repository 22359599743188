module.exports = {
    site: {
        // host: 'servlyapp.com',
        host: 'stg.bepaid.in',
        ckc: 'changelog', //client
        csc: 'viewlog', // customer
        loc: 'searchlog' // local
    },
    
    api : {
        // Prod
        // basepoint : 'https://api.servlyapp.com/api/',
        //Pre Prod
        basepoint : 'https://api.bepaidapp.xyz/api/',
        login: 'Login/authenticate',
        otp: 'Login/sendotp',
        resetPassword: 'Login/updatepassword',
        createComapny: 'Company/createcompany',
        addClient : 'ClientMaster/createupdateclient',
        getallClient: 'ClientMaster/getallclient',
        getcountry: 'Common/getcountrylist',
        getClient: 'ClientMaster/getclientbyid',
        delClient: 'ClientMaster/deleteclient',
        addservice: 'Services/createupdateservice',
        allservice: 'Services/getallservice',
        getService: 'Services/getservice',
        delService: 'Services/deleteservice',
        createorder: 'Order/createupdateorder',
        getAllOrders: 'Order/getallorders',
        delOrderApi: 'Order/deleteorder',
        getOrdersDetails: 'Order/getorderbyid',
        getOrdersBiIdApi: 'Order/gettasks',
        createTask: 'Order/createupdatetask',
        getAllTask: 'Order/getalltasks',
        //getAllTask: 'Order/gettasks',
        getTaskDetails: 'Order/gettaskbyid',
        updateTaskStatus: 'Order/changetaskstatus',
        updateOrderStatus: 'Order/changeorderstatus',
        addComment: 'Order/addcomment',
        getComment: 'Order/getcomments',
        getAllInvoice: 'Invoice/getallinvoices',
        createInvoice: 'Invoice/createupdateinvoice',
        deleteInvoice: 'Invoice/deleteinvoice',
        getInvoiceById: 'Invoice/getinvoice',
        downloadInvoice: 'Invoice/downloadinvoice',
        downloadfile: 'Order/downloadfile',
        getSmtp: 'SMTPDetail/getsmtpdetail',
        addSmtp: 'SMTPDetail/createsmtpdetail',
        createEmail: 'EmailTemplate/createtemplate',
        getAllEmail: 'EmailTemplate/getallemailtemplate',
        getEmail: 'EmailTemplate/getemailtemplate',
        delEmail: 'EmailTemplate/deleteemailtemplate',
        logApi: 'Common/WriteLog',
        getCompany: 'Company/GetCompany',
        updateComapny: 'Company/updatecompany',
        updateLogo: 'Company/uploadlogo',
        getBranding: 'Company/GetBranding',
        activePayment: 'PGConfig/setpgdetail',
        setpaymentDetails: 'PGConfig/setpaypaldetail',
        getpaymentDetails: 'PGConfig/getpaypaldetails',
        checkout: 'Invoice/creatonlineinvoice',
        paysuccess: 'Invoice/success',
        paycancel: 'Invoice/cancel',
        dashboard: 'Dashboard/dashboard',
        clientDashboard: 'client/clientdashboard/clientdashboard',
        getAllClientInvoice: 'client/ClientInvoice/getallinvoices',
        getAllClientOrders: 'client/ClientOrder/getallorders',
        getClientInvoiceById: 'client/ClientInvoice/getinvoice',
        downloadClientInvoice: 'client/ClientInvoice/downloadinvoice',
        updateDomain: 'Company/updatedomain',
        discountList: 'Discount/getallcoupons',
        deleteCoupon: 'Discount/deletecoupon',
        addCoupon: 'Discount/createupdatecoupon',
        discountDetail: 'Discount/getcoupon',
        getSubscription: 'Subscription/getsubscriptions',
        getSubscriptionDetail: 'Subscription/getsubscriptiondetail'
    },

    indicator : {
        yes: 'Y',
        no: 'N',
        per: 'Percentage',
        fix: 'Fixed',
        forever: 'Forever',
        firstpay: 'FirstPayment',
        defaultcurerncy: '&#x20B9;'
    },

    personalize : {
        currency: "₹",
        bgColor: "#f1f1f7",
        sideBarColor: "#fff",
        accentColor: "#007bff",
        userText: 'User',
    },

    profile: {
        admin : 1,
        customer : 2,
        client : 3
    },

    msg : {
        error: 'There are some technical issues. Please try after sometime.',
        payfaluire: "It looks like your billing has failed. We're not sure how this happened. Click below to retry.",
        regSuccess: "Thank you! Your registration was successful! Confirm your registration by clicking on the link sent in our E-mail and completing your profile."
    },

    task : {
        0 : 'InProgress',
        1 :  'Completed',
        2 : 'Open',
        3 : 'OnHold',
        4 : 'Open', //Reopen
    },

    payment : {
        paypal: 'PayPal'
    },
    
    monthNames : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
}