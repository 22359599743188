import * as actionTypes from '../../action/countrylist/CountryList'

const initialState = {
    countries: {},
    error: false,
    isFetching: false,
    errormessage: ''
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_COUNTRY_SUCCESS :
            return {
                ...state,
                isFetching: false,
                error: false,
                countries: action.payload
            };
            
        case actionTypes.GET_COUNTRY_FAILUR :
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        
        default:
            return state;
    }
}

export default reducer;