import { getData } from '../../utill/api'
import config from '../../utill/config'

export const GET_PROJECT_FETCH = 'GET_PROJECT_FETCH';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_MESSAGE = 'GET_PROJECT_MESSAGE';


export const fetch_Order_Details = (props) => {
  
    let getOrdersBiIdApi = config.api.basepoint + config.api.getOrdersDetails;
    
    props._fetchOrdersDetails();

    getData({url:getOrdersBiIdApi, auth:'token', customheader:{orderid: props.params.id}})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          props._oredersMessage(data.message)
        }
        //On Success
        else{
          props._loadOrders(data)
        }
    })
    .catch(error => {
      console.log(error)
      props._ordersFailure(error)
    });
}