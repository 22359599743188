import { getData } from './../../utill/api'
import config from './../../utill/config'

export const GET_TASK_MESSAGE = 'GET_TASK_MESSAGE';
export const GET_TASK_FAILURE = 'GET_TASK_FAILURE';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const GET_TASK_FETCH = 'GET_TASK_FETCH'




export const fetch_Task_List = (props, orderId) => {
  
    let getAllTask = config.api.basepoint + config.api.getAllTask;
    
    props._fetchTasklist()
  
    getData({url:getAllTask, auth:'token', customheader:{orderid: orderId}})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          props._taskMessage(data.message)
        }
        //On Success
        else{
          props._loadTask(data)
        }
    })
    .catch(error => {
      console.log(error)
      props._taskFailure(error)
    });
  }