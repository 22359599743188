import React, {Component} from 'react';
import config from './config'
import { postData, getData } from './api';

export const _sessionStorage = () => {
    if (window === undefined || !(window.sessionStorage)) return false;
  
    const _set = (key, value) => {
      return window.sessionStorage.setItem(key, value)
    }
  
    const _get = (key) => {
      return window.sessionStorage.getItem(key)
    }

    const _del = (key) => {
        return window.sessionStorage.removeItem(key)
    }

    // taget should be json object
    const _update = (key, taget) => {
      let prevData = JSON.parse(sessionStorage.getItem(key));
      Object.keys(taget).forEach(function(val, i){
          prevData[val] = taget[val];
      })
      sessionStorage.setItem(key, JSON.stringify(prevData));
    }
  
    return {
      set: _set,
      get: _get,
      del: _del,
      upd: _update
    }
}

export const _localStorage = () => {
  if (window === undefined || !(window.localStorage)) return false;

  const _set = (key, value) => {
    return window.localStorage.setItem(key, value)
  }

  const _get = (key) => {
    return window.localStorage.getItem(key)
  }

  const _del = (key) => {
      return window.localStorage.removeItem(key)
  }

  const _clear = () => {
    return window.localStorage.clear();
  }

  // target should be json object
  const _update = (key, taget) => {
    let prevData = JSON.parse(localStorage.getItem(key));
    Object.keys(taget).forEach(function(val, i){
        if(prevData[val]){
          prevData[val] = taget[val];
        }
    })
    localStorage.setItem(key, JSON.stringify(prevData));
  }

  return {
    set: _set,
    get: _get,
    del: _del,
    clr: _clear,
    upd: _update
  }
}

export const _getCookie = name => {
  let result = name ? undefined : {};
  let cookies = document.cookie ? document.cookie.split("; ") : [];
  for (let i = 0, l = cookies.length; i < l; i++) {
    let parts = cookies[i].split("=");
    let nameK = decodeURIComponent(parts.shift());
    let cookie = parts.join("=");
    cookie = _parseCookieValue(cookie);
    if (name && name === nameK) {
      result = cookie;
      break;
    }
    if (!name && cookie !== undefined) {
      result[nameK] = cookie;
    }
  }
  return result;
};

const _parseCookieValue = s => {
  if (s.indexOf('"') === 0) {
    // This is a quoted cookie as according to RFC2068, unescape...
    s = s
      .slice(1, -1)
      .replace(/\\"/g, '"')
      .replace(/\\\\/g, "\\");
  }
  try {
    // If we can't decode the cookie, ignore it, it's unusable.
    // Replace server-side written pluses with spaces.
    return decodeURIComponent(s.replace(/\+/g, " "));
  } catch (e) {}
};

export const _setCookie = (cname, cvalue, exdays) => {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
};

export const _delCookie = (cname) => {
  document.cookie = cname + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const logout = () => {
    if(window.sessionStorage){
        _localStorage().del(config.site.loc);
        _delCookie(config.site.ckc, 1);
        _delCookie(config.site.csc, 1);
        window.location = '/login'
    }
}

export const islogged = (role, callback) => {
    if(_getCookie(role) != 1){
      window.location = '/login'
    }
    else if(callback){
      callback()
    }
}

export const toggleAllChecked = (source,name) => {
  let checkboxes = document.getElementsByName(name);
  if(checkboxes.length > 0){
    for(var i=0, n=checkboxes.length;i<n;i++) {
      checkboxes[i].checked = document.querySelector(source).checked;
    }
  }
}

export const showPop = () => {
  if(document.querySelector('[data-attr="popup"]') && document.querySelector('.overlay')){
    document.querySelector('[data-attr="popup"]').classList.add('active');
    document.querySelector('.overlay').classList.add('active');
  }
}

export const closePop = () => {
  if(document.querySelector('[data-attr="popup"]') && document.querySelector('.overlay')){
    document.querySelector('[data-attr="popup"]').classList.remove('active');
    document.querySelector('.overlay').classList.remove('active');
  }
}

export const showMsg = ({msg, event}) => {
  let ele = document.querySelector('[data-attr="msg_sum"]');
  if(ele != undefined){
    ele.textContent = msg;

    ele.classList.add('msg_' + event);

    ele.style.top = '0px';

    setTimeout(function(){ 
      ele.style.top = '-100px';
      ele.classList.remove('msg_' + event);
    }, 3000);
  }
}


export const delClient = ({event, id, callback, props}) => {
  event.preventDefault();
  var deleteConfirm = window.confirm("Do you want to delete this record?");
  if (deleteConfirm) {
    let delApi = config.api.basepoint + config.api.delClient;
  
    if (id != undefined){
      postData({url: delApi, auth: 'token', customheader: {clientId: id}})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          showMsg({msg:data.message, event:'message'});
          callback(props);
        }
        //On Success
        else{
          if(callback && data === true){
            callback(props);
            showMsg({msg:'Record Deleted', event:'success'});
          }
        }
      })
      .catch(error => {
        console.log(error)
        showMsg({msg:'There are some technical issues. Please try after sometime.', event:'error'})
      });
    }
    else{
      showMsg({msg:'There are some technical issues. Please try after sometime.', event:'error'})
    }
  }
}

export const getlabel = ({status, type}) => {
  let clsname = '';
  status = status.toLocaleLowerCase();
  status = status == 'paymentinitiated' ? 'initiated' : status;

  switch(status) {
    case 'open':
      clsname = 'primary' 
      break;
    case 'close':
      clsname = 'success' 
      break;
    case 'paid':
      clsname = 'success' 
      break;
    case 'completed':
      clsname = 'success' 
      break;

    case 'inprogress':
      clsname = 'warning'
      break;
    case 'initiated':
        clsname = 'warning'
        break;
    case 'cancel':
      clsname = 'danger'
      break;
    case 'pending':
        clsname = 'primary'
        break;
    default:
      clsname = 'secondary' 
  }

  return (
    type == 'color' ? clsname : <span className={'badge badge-' + clsname}>{ status }</span>
  )
}

export const formateDate = (dateString) => {
  //var dt = new Date(dateString + 'Z')
  var dt = new Date(dateString)
  if(dt != ''){
    dt = config.monthNames[dt.getMonth()] + ' ' + dt.getDate() + ', ' + dt.getFullYear();
    dt = dt.toString();
    return dt
  }
  else{
    return null
  }
}

export const closeModal = () => {
  document.querySelector('.close').click();
}

export const gevent = ({cat, act, lbl, val}) => {
  //ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
  if(cat == 'email'){cat = 'emailJsError'}
  if(window && window.ga){
    if(typeof(val) != 'number'){
      val = 0;
    }
    window.ga('send', 'event', cat, act, lbl, val);
  }
}

// For error Logging
export const logfile = ({event='', method='', path='', status='', error='', createdDate=''}) => {
  
  let logApi = config.api.basepoint + config.api.logApi;
  let eventType = '';
  if(event == 0){ //For Error
    eventType = 'Error'
  }else if(event == 1){ //For Warning
    eventType = 'Warning'
  }else if(event == 2){ //For Info
    eventType = 'Info'
  }

  let logdata = {
    "EventType": eventType, //Info / Error / Warning
    "MethodName": method,
    "Path": path,
    "StatusCode": status,
    "ErrorDetail": typeof(error) == 'object' ? JSON.stringify(error) : error,
    //"LogGenDate": createdDate
  }
  //console.log(logdata)
  postData({url: logApi, auth: 'token', data: logdata})
  .then(data => {
    //If there is some error, print message
    if(data.message){
      console.log(data.message)
    }
    //On Success
    else{
      console.log('Logged')
    }
  })
  .catch(error => {
    console.log(error)
  });
}


// For SEO Meta
export const pagemeta = ({title = 'Servly', obj}) => {
  document.title = title;
  if(obj != undefined){
    // setting fevicon
    if(obj.favicon){
      document.querySelector("link[rel*='icon']").setAttribute('href', obj.favicon)
    }
    // setting fevicon
    if(obj.logo){
      document.querySelector('[data-attr="logo"]').setAttribute('src', obj.logo)
    }
  }
}

export const getBranding = (callback) => {
  let brandApi = config.api.basepoint + config.api.getBranding;

  let domains = setDomains();
  
  let subDomain = domains.subDomain;
  let domainName = domains.domain;
  getData({url: brandApi, customheader:{ subDomain : subDomain != null ? subDomain : null, domain: domainName }})
  .then(data => {
    //If there is some error, print message
    if(data.message){
      console.log(data.message)
      logfile({event:1, method:'getBranding', error: data.message})
    }
    else if(data.error){
      console.log(data.error)
    }
    //On Success
    else{
      let searchlog = {};
      searchlog.brand = data;
      if(_localStorage().get(config.site.loc)){
        _localStorage().upd(config.site.loc, searchlog)
      }
      else{
        _localStorage().set(config.site.loc, JSON.stringify(searchlog))
      }

      if(callback){callback()}
    }
  })
  .catch(error => {
    console.log(error.message)
    logfile({event:0, method:'getBranding', error: error.message})
  });
}


export const setDomains = () => {
  if(window){
    let host = window.location.host
    
    // Check of host has servly then pick subdomain else domain
    let domains = { subDomain: '', domain: '' };
    
    if (host.indexOf(config.site.host) > -1 || host.indexOf('localhost') > -1 || host.indexOf('192.168.0.200') > -1){
        var parts = host.split('.');
        var subdomain = parts.shift();
        if(subdomain.indexOf('localhost') > -1 || host.indexOf('192.168.0.200') > -1){
            subdomain = 'gyf';
        }
        domains.subDomain = subdomain;
    }
    else{
      domains.domain = host;
    }

    // window.servlyCustomer = domains;
    return domains;
  }
  else{
    return false
  }
}

export const loggedIn = () => {
  // ...
}

export const isLoggedIn = () => {
  // ...
}


export const isClient = () => {
  let rl = _localStorage().get(config.site.loc);
  rl = JSON.parse(rl);

  if(rl.key && rl.key == 3){
    return true;
  }
  else{
    return false;
  }
}

export const getNextSibling = (elem, selector) => {
  if(!elem) return false;
	// Get the next sibling element
	var sibling = elem.nextElementSibling;

	// If there's no selector, return the first sibling
	if (!selector) return false;

	// If the sibling matches our selector, use it
	// If not, jump to the next sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.nextElementSibling
	}

};

export const findParent = (elm, selector) => {
  var all = document.querySelectorAll(selector);
  var cur = elm.parentNode;
  while(cur && !collectionHas(all, cur)) { //keep going up until you find a match
      cur = cur.parentNode; //go up
  }
  return cur; //will return null if not found
}

// to get parents
const collectionHas = (a, b) => { //helper function (see below)
  for(var i = 0, len = a.length; i < len; i ++) {
      if(a[i] == b) return true;
  }
  return false;
}


export const randInt = () => {
  let rint = 0;
  rint = Math.floor((Math.random() * 100) + 1)
  return rint
}