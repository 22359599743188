import * as actionTypes from '../../action/services/Services'

const initialState = {
    services: {},
    error: false,
    isFetching: false,
    errormessage: ''
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_SERVICES_FETCH:
            return {
                ...state,
                isFetching: true
            };
        case actionTypes.GET_SERVICES_SUCCESS :
            return {
                ...state,
                isFetching: false,
                error: false,
                services: action.payload
            };
        
        case actionTypes.GET_SERVICES_FAILURE :
            return {
                ...state,
                isFetching: false,
                error: action.payload,
                isError: true
            };
        
        case actionTypes.GET_SERVICES_MESSAGE:
            return {
                ...state,
                isFetching: false,
                error: false,
                errormessage: action.payload
            };
        
        default:
            return state;
    }
}

export default reducer;