import * as actionTypes from '../../action/getservice/GetService'

const initialState = {
    serviceinfo:{},
    error: false,
    isFetching: false,
    errormessage: ''
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_SERVICE_FECTH :
            return {
                ...state,
                isFetching: true,
                error: false,
            };
        case actionTypes.GET_SERVICE_SUCCESS :
            return {
                ...state,
                serviceinfo: action.payload,
                isFetching: false,
                error: false,
            };
        
        case actionTypes.GET_SERVICE_FAILURE :
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        
        case actionTypes.GET_SERVICE_MESSAGE:
            return {
                ...state,
                isFetching: false,
                error: false,
                errormessage: action.payload
            };
        
        default:
            return state;
    }
}

export default reducer;