import { getData, postData } from '../../utill/api'
import config from '../../utill/config'
import { showMsg, gevent } from '../../utill/lib';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_GETEMAILTEMPLATE_FETCH = 'GET_GETEMAILTEMPLATE_FETCH';
export const GET_GETEMAILTEMPLATE_FAILURE = 'GET_GETEMAILTEMPLATE_FAILURE';
export const GET_GETEMAILTEMPLATE_SUCCESS = 'GET_GETEMAILTEMPLATE_SUCCESS';
export const GET_GETEMAILTEMPLATE_MESSAGE = 'GET_GETEMAILTEMPLATE_MESSAGE';


export const fetch_allemails = (props) => {
  
    let getAllEmailApi = config.api.basepoint + config.api.getAllEmail;
    
    props._fetchAllEmail();

    try{
      getData({url:getAllEmailApi, auth:'token'})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          props._emailAllMessage(data.message)
        }
        //On Success
        else{
          props._loadAllEmail(data)
        }
      }).catch(error => {
        console.log(error)
        props._emailAllFailure(error)
      });
  }catch(err){
      console.log(err)
  }
}

export const delEmail = (props, id, callback) => {
  var deleteConfirm = window.confirm("Do you want to delete this record?");
  
  if (deleteConfirm) {
    let delApi = config.api.basepoint + config.api.delEmail;
  
    props._fetchAllEmail();

    if (id != undefined){
      postData({url: delApi, auth: 'token', customheader: {emailTemplateId: id}})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          showMsg({msg:data.message, event:'message'})
          props._emailAllMessage(data.message)
        }
        //On Success
        else{
          showMsg({msg:'Deleted', event:'success'});
          
          fetch_allemails(props)
          
          if(callback){
            callback();
          }
        }
      })
      .catch(error => {
        console.log(error)
        props._emailAllFailure(error);
        showMsg({msg:'There are some technical issues. Please try after sometime.', event:'error'})
      });
    }
    else{
      console.log('id not defined');
      props._emailAllFailure('id not defined');
      showMsg({msg:'There are some technical issues. Please try after sometime.', event:'error'})
    }
  }
}