import * as actionTypes from '../../action/pgsetting/PgSetting'

const initialState = {
    error: false,
    isFetching: false,
    errormessage: '',
    pgdetails: {}
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_PGDETAILS_FETCH:
            return {
                ...state,
                isFetching: true
            };
        case actionTypes.GET_PGDETAILS_SUCCESS :
            return {
                ...state,
                isFetching: false,
                error: false,
                pgdetails: action.payload
            };
        
        case actionTypes.GET_PGDETAILS_FAILURE :
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        
        case actionTypes.GET_PGDETAILS_MESSAGE:
            return {
                ...state,
                isFetching: false,
                error: false,
                errormessage: action.payload
            };
        
        default:
            return state;
    }
}

export default reducer;