import { getData } from './../../utill/api'
import config from './../../utill/config'

export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILUR = 'GET_COUNTRY_FAILUR';




//Fetch Country List for dropdown
export const fetchCountryList = (props) => {
    let countryapi = config.api.basepoint + config.api.getcountry;

    getData({url:countryapi})
    .then((data) => {
        if(data){
            props._loadCountry(data)
        }else{
            props._countryFailure(data)
        }
    })
    .catch( error => {
        props._countryFailure(error)
    });
}