import { getData } from './../../utill/api'
import config from './../../utill/config'

export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_MESSAGE = 'GET_CLIENT_MESSAGE';
export const GET_CLIENT_FECTH = 'GET_CLIENT_FECTH';

//Fetch Country List for dropdown
export const fetchClientInfo = (props, id) => {
    
    //dispatch action to set isfetching === true
    props._fectchingInfo();

    let clientInfoApi = config.api.basepoint + config.api.getClient;

    getData({url:clientInfoApi, auth:'token', customheader:{clientId: id}})
    .then((data) => {
        props._loadClientInfo(data)
    })
    .catch( error => {
        props._clientInfoFailure(error)
    });
}