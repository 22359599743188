import { getData } from '../../utill/api'
import config from '../../utill/config'

export const GET_COMMENTS_FETCH = 'GET_COMMENTS_FETCH';
export const GET_COMMENTS_MESSAGE = 'GET_COMMENTS_MESSAGE';
export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';


export const fetch_Comments = (props, oid, tid) => {
  let getCommentApi = config.api.basepoint + config.api.getComment;
    
  props._fetchComments();

  getData({url:getCommentApi, auth:'token', customheader:{OrderId: oid, TaskId: tid}})
  .then(data => {
    //If there is some error, print message
    if(data.message){
      props._commentsMessage(data.message)
    }
    //On Success
    else{
      props._loadComments(data)
    }
  })
  .catch(error => {
    console.log(error)
    props._commentsFailure(error)
  })
}