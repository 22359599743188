import config from '../../utill/config'
import { showMsg, isClient } from '../../utill/lib'
import { getData } from '../../utill/api';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_INVOICE_LIST_FETCH = 'GET_INVOICE_LIST_FETCH';
export const GET_INVOICE_LIST_FAILURE = 'GET_INVOICE_LIST_FAILURE';
export const GET_INVOICE_LIST_SUCCESS = 'GET_INVOICE_LIST_SUCCESS';
export const GET_INVOICE_LIST_MESSAGE = 'GET_INVOICE_LIST_MESSAGE';

// For Details
export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';


export const fetch_Invoice_List = (props) => {
    props._fetchInvoiceList();

    let getAlInvocieApi = config.api.basepoint + (isClient() ? config.api.getAllClientInvoice : config.api.getAllInvoice);
    getData({url: getAlInvocieApi, auth:'token'})
    .then(data => {
      //If there is some error, print message
      if(data.message){
        props._invoiceListMessage(data.message);
        showMsg({msg:data.message, event:'message'})
      }
      else if(data.error){
        props._invoiceListFailure(data.error);
        showMsg({msg:config.msg.error, event:'message'})
      }
      //On Success
      else{
        props._loadInvoiceList(data)
      }
    })
    .catch(error => {
      console.log(error)
      props._invoiceListFailure(error);
    });
}

export const fetchInvoiceDetails = (props, id) => {
    
  let getInvoiceById = config.api.basepoint + ( isClient() ? config.api.getClientInvoiceById : config.api.getInvoiceById )

  getData({url:getInvoiceById, auth:'token', customheader:{invoiceid: id}})
    .then(data => {
      //If there is some error, print message
      if(data.message){
        showMsg({msg:'There are some technical issues or ID is invalid.', event:'error'});
        return false;
      }
      //On Success
      else{
          props._fetchInvoiceSuccess(data);
      }
  })
  .catch(error => {
    console.log(error)
    showMsg({msg:'There are some technical issues or ID is invalid.', event:'error'});
    return false;
  });
}