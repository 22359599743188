import * as actionTypes from '../../action/subscription/Subscription'

const initialState = {
    error: false,
    isFetching: false,
    errormessage: ''
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_SUBSCRIPTION_LIST_FETCH:
            return {
                ...state,
                isFetching: true
            };
        case actionTypes.GET_SUBSCRIPTION_LIST_SUCCESS :
            return {
                ...state,
                isFetching: false,
                error: false,
                subscription: action.payload
            };
        
        case actionTypes.GET_SUBSCRIPTION_LIST_FAILURE :
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        
        case actionTypes.GET_SUBSCRIPTION_LIST_MESSAGE:
            return {
                ...state,
                isFetching: false,
                error: false,
                errormessage: action.payload
            };
        
        case actionTypes.GET_SUBSCRIPTION_DETAILS:
            return {
                ...state,
                isFetching: false,
                error: false,
                invoiceDatails: action.payload
            }
        
        case actionTypes.GET_SUBSCRIPTIONDETAIL_FETCH:
            return {
                ...state,
                isFetching: true
            }

        default:
            return state;
    }
}

export default reducer;