import { getData } from './../../utill/api'
import config from './../../utill/config'
import { isClient } from '../../utill/lib';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_ORDERS_FETCH = 'GET_ORDERS_FETCH';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_MESSAGE = 'GET_ORDERS_MESSAGE';
export const DONE  = 'DONE';

export const fetch_Order_List = (props) => {
  
    let getAllOrdersApi = config.api.basepoint + (isClient() ? config.api.getAllClientOrders : config.api.getAllOrders);
    
    props._fetchOrders();
    
    getData({url:getAllOrdersApi, auth:'token'})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          props._oredersMessage(data.message)
        }
        //On Success
        else{
          props._loadAllOrders(data)
        }
    })
    .catch(error => {
      console.log(error)
      props._ordersFailure(error)
    });
}