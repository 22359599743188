import config from './../../utill/config';
import { getData, postData } from '../../utill/api';
import { showMsg } from '../../utill/lib';

export const CLIENT_EDIT_FETCH = 'CLIENT_EDIT_FETCH';
export const CLIENT_EDIT_SUCCESS = 'CLIENT_EDIT_SUCCESS';
export const CLIENT_EDIT_MESSAGE = 'CLIENT_EDIT_MESSAGE';
export const CLIENT_EDIT_FAILURE = 'CLIENT_EDIT_FAILURE';


export const fetch_Client_Details = ({props, id}) => {
    props._edit_Fetching_Info();

    //Get Client Data
    let clientInfoApi = config.api.basepoint + config.api.getClient;
    getData({url:clientInfoApi, auth:'token', customheader:{clientId: id}})
    .then((data) => {
        props._edit_Fetch_Success(data)
    })
    .catch( error => {
        props._edit_Fetch_Failure(error)
    });

}

export const update_client_info = ({data, props}) => {
    let addClientApi = config.api.basepoint + config.api.addClient;
        
    postData({ url:addClientApi, data:data, auth: 'token', customheader: data.client.id != undefined ? {clientId: data.client.id} : null })
    .then(data => {
    //If there is some error, print message
    if(data.message){
        showMsg({msg:data.message, event:'message'})
    }
    //On Success
    else{
        showMsg({msg:'Information Update Sucessfuly', event:'success'});
        document.querySelector('[href="/client"]').click();
    }
    })
    .catch(error => {
        console.log(error)
        showMsg({msg:'There are some technical issues. Please try after sometime.', event:'error'});
        document.querySelector('[href="/client"]').click();
    });
}

