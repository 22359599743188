import * as actionTypes from '../../action/invoices/Invoices'

const initialState = {
    error: false,
    isFetching: false,
    errormessage: ''
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_INVOICE_LIST_FETCH:
            return {
                ...state,
                isFetching: true
            };
        case actionTypes.GET_INVOICE_LIST_SUCCESS :
            return {
                ...state,
                isFetching: false,
                error: false,
                invoicelist: action.payload
            };
        
        case actionTypes.GET_INVOICE_LIST_FAILURE :
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        
        case actionTypes.GET_INVOICE_LIST_MESSAGE:
            return {
                ...state,
                isFetching: false,
                error: false,
                errormessage: action.payload
            };
        
        case actionTypes.GET_INVOICE_DETAILS:
            return {
                ...state,
                isFetching: false,
                error: false,
                invoiceDatails: action.payload
            }

        default:
            return state;
    }
}

export default reducer;