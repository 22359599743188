import { getData } from './../../utill/api'
import config from './../../utill/config'
import { setDomains } from '../../utill/lib';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_MESSAGE = 'GET_SERVICES_MESSAGE';
export const GET_SERVICES_FETCH = 'GET_SERVICES_FETCH';

export const fetch_Services_List = (props) => {
  
    let GetServiceApi = config.api.basepoint + config.api.allservice;
    let domains = setDomains();
    props._servicesFetch();

    getData({url:GetServiceApi, customheader:{ subdomain: domains.subDomain, domain: domains.domain}})
      .then(data => {
        if(data == undefined){
          props._failure()
        }
        //If there is some error, print message
        else if(data.message){
          props._message(data.message)
        }
        //On Success
        else{
          props._loadAllServices(data)
        }
    })
    .catch(error => {
      console.log(error)
      props._failure(error)
    });
}