import { getData } from './../../utill/api'
import config from './../../utill/config'

export const GET_TASKDETAILS_FETCH = 'GET_TASKDETAILS_FETCH';
export const GET_TASKDETAILS_MESSAGE = 'GET_TASKDETAILS_MESSAGE';
export const GET_TASKDETAILS_FAILURE = 'GET_TASKDETAILS_FAILURE';
export const GET_TASKDETAILS_SUCCESS = 'GET_TASKDETAILS_SUCCESS';




export const fetch_Task_Details = (props, orderid, taskid) => {
    
    let getTaskDetails = config.api.basepoint + config.api.getTaskDetails;
    
    props._fetchTaskDetails();
    
    getData({url:getTaskDetails, auth:'token', customheader:{OrderId: orderid, TaskId: taskid}})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          props._taskShowMessage(data.message)
        }
        //On Success
        else{
          props._loadTaskDetails(data)
        }
    })
    .catch(error => {
      console.log(error)
      props._taskDetailsFailure(error)
    });
}
