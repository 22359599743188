import * as actionTypes from './../../action/editclient/EditClient'

const initialState = {
    error: false,
    isFetching: false,
    errormessage: '',
    redirectToReferrer: false
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.CLIENT_EDIT_FETCH :
            return {
                ...state,
                isFetching: true,
                error: false
            };

        case actionTypes.CLIENT_EDIT_SUCCESS :
            return {
                ...state,
                isFetching: false,
                error: false,
                editclient: action.payload,
            };
        
        case actionTypes.CLIENT_EDIT_MESSAGE :
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        
        case actionTypes.CLIENT_EDIT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        
        default:
            return state;
    }
}

export default reducer;