import { getData } from '../../utill/api'
import config from '../../utill/config'

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_SMTP_FETCH = 'GET_SMTP_FETCH';
export const GET_SMTP_FAILURE = 'GET_SMTP_FAILURE';
export const GET_SMTP_SUCCESS = 'GET_SMTP_SUCCESS';
export const GET_SMTP_MESSAGE = 'GET_SMTP_MESSAGE';


export const fetch_Smtp_Details = (props) => {
  
    let getSmtpApi = config.api.basepoint + config.api.getSmtp;
    
    props._fetchSmtp();

    try{
      getData({url:getSmtpApi, auth:'token'})
      .then(data => {
        //If there is some error, print message
        if(data.message){
          props._smtpMessage(data.message)
        }
        //On Success
        else{
          props._loadSmtp(data)
        }
      }).catch(error => {
        console.log(error)
        props._smtpFailure(error)
      });
  }catch(err){
      console.log(err)
  }
}