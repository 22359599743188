import React, { Component } from 'react';

class Btn extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
    };
  }

  render(){
    const { click, cssclass, text } = this.props
    return (  
      <div className="btn_con">
        <input type="submit" data-attr="btnCommn" value={ text ? text : 'Submit' } onClick={ (e) => click ? click(e) : null } className={ cssclass ? 'btn ' + cssclass : 'btn btn-primary' } />
        
        <ul className="con_spinner" data-attr="btn_cmn">
            <li className="spinner-grow spinner-grow-sm"></li>
            <li className="spinner-grow spinner-grow-sm"></li>
            <li className="spinner-grow spinner-grow-sm"></li>
            <li className="spinner-grow spinner-grow-sm"></li>
        </ul>

      </div>
    );
  }
}

export default Btn;

export const resetBtn = () => {
  if(document.querySelector('[data-attr="btnCommn"]') && document.querySelector('[data-attr="btn_cmn"]')){
    document.querySelector('[data-attr="btnCommn"]').style.opacity = '1';
    document.querySelector('[data-attr="btn_cmn"]').style.display = "none";
  }
}

export const setBtn = (custom) => {
  if(document.querySelector('[data-attr="btnCommn"]') && document.querySelector('[data-attr="btn_cmn"]')){
    document.querySelector('[data-attr="btnCommn"]').style.opacity = '0.5';
    document.querySelector('[data-attr="btn_cmn"]').style.display = "block";
  }
}

export const addListner = () => {
    // get all the elements with className 'btn'. It returns an array
    var btnList = document.querySelectorAll('[data-attr="btnCommn"]');
    
    // get the lenght of array defined above
    var listLength = btnList.length;
    
    // run the for look for each element in the array
    for(var i=0; i < listLength; i++){
        // attach the event listener
        btnList[i].addEventListener("click", setBtn);
    }
}