import { getData, postData } from '../../utill/api'
import config from '../../utill/config'
import { setDomains, showMsg } from '../../utill/lib';

//export const GET_ALL_CLIENT = 'GET_ALL_CLIENT';
export const GET_SERVICEDETAILS_FETCH = 'GET_SERVICEDETAILS_FETCH';
export const GET_SERVICEDETAILS_FAILURE = 'GET_SERVICEDETAILS_FAILURE';
export const GET_SERVICEDETAILS_SUCCESS = 'GET_SERVICEDETAILS_SUCCESS';
export const GET_SERVICEDETAILS_MESSAGE = 'GET_SERVICEDETAILS_MESSAGE';


export const fetchServiceDetails = ({props, serviceid,subDomain,domain,IsPubllicAccess, email, code}, callback) => {
  
    //Trigger action fetch
    props._fetching();
    
    let getServiceApi = config.api.basepoint + config.api.getService;
    let customheader = {'serviceid':serviceid, 'subDomain':subDomain, 'domain':domain, 'IsPubllicAccess':true}
    if(email != undefined && code != undefined) { 
      customheader.email = email;
      customheader.CouponCode = code;
    }
    
    try{
      getData({url:getServiceApi, customheader})
      .then(data => {
        //If there is some error, print message
        if(data && callback){
          callback(true, data);
        }else{
          callback(false, data);
        }
      }).catch(error => {
        props._failure(error.message)
      });
  }catch(err){
      props._failure(err)
      console.log(err)
  }
}

export const sendCheckout = (form) => {
  let checkoutApi = config.api.basepoint + config.api.checkout;
  try{
    let domains = setDomains();
    postData({url: checkoutApi, data: form, customheader:{domain: domains.domain, subDomain: domains.subDomain}})
    .then( data => {
      if(data && data.response && data.response.data && data.response.data.error != undefined){
        showMsg({msg: 'Ther is technical issue', event:'error'})
      }
      else if(data.url != undefined){
        window.location = data.url;
      }
      else{
        showMsg({msg: 'Ther is technical issue', event:'error'})
      }
    })
    .catch(err => {
      console.log(err.message)
    })
  }
  catch(err){
      console.log(err)
  }
}