import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux'
import { BrowserRouter } from 'react-router-dom';
import App from './App'
import getClient from './store/clients/Clients';
import viewclient from './store/viewclient/ViewClient';
import editclient from './store/editclient/EditClient';
import countrylist from './store/countrylist/CountryList';
import addClinet from './store/addclient/AddClient';
import services from './store/services/Services';
import serviceinfo from './store/getservice/GetService';
import orders from './store/orders/Orders';
import project  from './store/projects/Projects';
import tasklist  from './store/tasklist/TaskList';
import taskDetails from './store/taskdetails/TaskDetails';
import commentlist from './store/commentslist/CommentsList';
import invoicelist from './store/invoices/Invoices';
import smtp from './store/smtp/Smtp';
import email from './store/addemail/AddEmail';
import emailtemplates from './store/emailtemplates/EmailTemplates';
import company from './store/company/Company';
import branding from './store/branding/Branding';
import pgdetails from './store/pgsetting/PgSetting';
import dashboard from './store/dashboard/Dashboard';
import servicedetail from './store/checkout/Checkout';
import discount from './store/discount/Discount';
import coupon from './store/adddiscount/AddDiscount';
import subscription from './store/subscription/subscription';
import { Provider } from 'react-redux';

const rootReducer = combineReducers({
    client: getClient,
    viewclient,
    editclient,
    countries: countrylist,
    addclient: addClinet,
    services: services,
    serviceinfo,
    orders: orders,
    project,
    tasklist,
    taskDetails,
    commentlist,
    invoicelist,
    smtp,
    emailtemplates,
    email,
    company,
    branding,
    pgdetails,
    servicedetail,
    dashboard,
    discount,
    coupon,
    subscription
});

const store = createStore(rootReducer);

ReactDOM.render(<Provider store={store}><BrowserRouter><App/></BrowserRouter></Provider>, document.getElementById('app'))



// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
