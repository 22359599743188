import {_sessionStorage, _localStorage, showMsg} from './lib'
import { resetBtn } from '../components/common/Btn';
import config from './config';
const axios = require('axios');

//Token : true/False
// export const postData = (url = '', data = {}, token, auth, customheader) => {
export const postData = async ({url, data, auth, customheader, ContentType, file}) => {
    let axconfig = {
        method: "post",
        url: url
    };

    try{
        let body = data != undefined ? data : {}
        let headerObj = {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            'Authorization': auth === 'token' ? 'Bearer ' + JSON.parse(_localStorage().get(config.site.loc)).token : 'None'
        }

        //No contenttype in case of file upload
        // To set content-type payload should not be blank
        if( ContentType != 'no' ) {
            headerObj['Content-Type'] = ( ContentType != undefined && ContentType != '' ? ContentType : 'application/json' )
        }
        
        if((typeof(body) == 'object' && Object.keys(body).length > 0) || file){
            //Passgin data to request
            axconfig.data = body;
        }
        else if(data === 'null'){
            axconfig.data = null;
        }

        axconfig.headers = customheader != undefined ? Object.assign(headerObj, customheader) : headerObj
        
        return await axios(axconfig)
        .then((response) => {
            if(response.data.success == true && response.data.result){
                return response.data.result;
            }else if(response.data.success == false && response.data.error && response.data.error.errorMessage){
                showMsg({msg: response.data.error.errorMessage, event: 'error'})
                return false
            }else{
                return response.data;
            }
        }, (error) => {
            if(error.response && error.response.data && error.response.data.error){
                return { error: error.response.data.error }
            }
            else if(error.response && error.response.data && error.response.data.message){
                return { message : error.response.data.message }
            }
            else if(error.isAxiosError){
                showMsg({msg: config.msg.error, event: 'error'});
            }
            else if(error.response && error.response.statusText){
                return { status : error.response.statusText }
            }
            else{
                showMsg({msg: config.msg.error, event: 'error'});
            }
        })
        .catch((error) => {
            if (error.response) {
                // console.log(error.response.data);
                return error.response.data;
            } 
            else if (error.request) {
                // console.log(error);
                return error.request;
            } 
            else {
                // console.log(error);
                return error.message;
            }
        })
        .finally(function () {
            // To reset animation on button
            resetBtn();
        });
    }
    catch(err){
        console.log(err.message)
        return err.message;
    }
}

export const postData1 = ({url, data, auth, customheader, ContentType, file}) => {
    try{
        let body = data != undefined ? data : {}
        let headerObj = {
            // 'Content-Type' : ContentType != undefined ? ContentType : 'application/json',
            
            'Authorization': auth === 'token' ? 'Bearer ' + JSON.parse(_localStorage().get(config.site.loc)).token : 'None',
            'Access-Control-Allow-Origin': '*'
        }

        //No contenttype in case of file upload
        if( ContentType != 'no' ) {
            headerObj['Content-Type'] = ( ContentType != undefined && ContentType != '' ? ContentType : 'application/json' )
        }
        
        if((typeof(body) == 'object' && Object.keys(body).length > 0) || file){
            return fetch(url, {
                crossDomain: true,
                method: 'POST',
                headers: customheader != undefined ? Object.assign(headerObj, customheader) : headerObj,
                body: file ? body : JSON.stringify(body),
            })
            .then(status)
            .then(json); // parses JSON response into native JavaScript objects
        }
        else{
            return fetch(url, {
                crossDomain: true,
                method: 'POST',
                headers: customheader != undefined ? Object.assign(headerObj, customheader) : headerObj,
            })
            .then(status)
            .then(json); // parses JSON response into native JavaScript objects
        }
    }
    catch(err){
        console.log(err.message)
    }
}

//Token : true/False
export const getData = async ({url = '', auth, customheader, method}) => {
    
    let axconfig = {
        method: method ? method : "POST",
        url: url,
        'Access-Control-Allow-Origin': '*'
    };
    try{
        let headerObj = {
            'Content-Type': 'application/json',
            'Authorization': auth === 'token' ? 'Bearer ' + JSON.parse(_localStorage().get(config.site.loc)).token : 'None'
        }
        
        axconfig.headers = customheader != undefined ? Object.assign(headerObj, customheader) : headerObj
        
        return await axios(axconfig)
        .then((response) => {
            if(response.data.success == true && response.data.result){
                return response.data.result;
            }else if(response.data.success == false && response.data.error && response.data.error.errorMessage){
                showMsg({msg: response.data.error.errorMessage, event: 'error'})
                return false
            }else{
                return response.data;
            }
        }, (error) => {
            if(error.response && error.response.data && error.response.data.error){
                return { error: error.response.data.error }
            }
            else if(error.response && error.response.data && error.response.data.message){
                return { message : error.response.data.message }
            }
            else if(error.isAxiosError){
                showMsg({msg: config.msg.error, event: 'error'});
            }
            else if(error.response && error.response.statusText){
                return { status : error.response.statusText }
            }
            else{
                showMsg({msg: config.msg.error, event: 'error'});
            }
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.headers);
                return error.response.headers
            } 
            else if (error.request) {
                console.log(error.request);
                return error.request
            } 
            else {
                console.log(error.message);
                return error.message
            }
            console.log(error.config);
        })
    }
    catch(err){
        console.log(err.message)
        return {error: err.message};
    }
}

export const getData1 = ({url = '', auth, customheader}) => {
    
    let headerObj = {
        'Content-Type': 'application/json',
        'Authorization': auth === 'token' ? 'Bearer ' + JSON.parse(_localStorage().get(config.site.loc)).token : 'None'
    }
    
    return fetch(url, {
        method: 'POST',
        headers: customheader != undefined ? Object.assign(headerObj, customheader) : headerObj
    })
    .then(status)
    .then(json)
}

export const status = (resp) => {
    if (resp.status == 200 || resp.status == 400) {
        return Promise.resolve(resp)
    } else {
        return Promise.reject(new Error(resp.statusText))
    }
}

export const json = (resp) => {
    return resp.json()
}