import * as actionTypes from '../../action/tasklist/TaskList'

const initialState = {
    error: false,
    taskListFetching: false,
    errormessage: '',
}

const reducer = (state = initialState, action) => {
    switch ( action.type ){
        case actionTypes.GET_TASK_FETCH :
            return {
                ...state,
                taskListFetching: true,
                error: false,
            };
        case actionTypes.GET_TASK_SUCCESS :
            return {
                ...state,
                taskListFetching: false,
                error: false,
                tasklist: action.payload
            };
        
        case actionTypes.GET_TASK_FAILURE :
            return {
                ...state,
                taskListFetching: false,
                error: action.payload
            };
        
        case actionTypes.GET_TASK_MESSAGE:
            return {
                ...state,
                taskListFetching: false,
                error: false,
                errormessage: action.payload
            };
        
        default:
            return state;
    }
}

export default reducer;